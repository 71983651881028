import "./Header.css";
import { IconButton, Stack, Button, Box } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export default function Header() {
    return (
      // <div className='Header' style={{ backgroundColor: '#132A37' }}>
      <Stack direction={"row"} spacing={"1rem"} padding={"1rem"}>
        <IconButton size="small" sx={{ color: "#b4dac3", backgroundColor: "#22485E"}}>
          <AccountBoxIcon fontSize="large"/>
        </IconButton>
        <Button variant="contained" size='large' style={{color: '#b4dac3', backgroundColor: "#132A37"}}>Sky Notify</Button>
        <Button variant="contained" size='large' style={{color: '#b4dac3', backgroundColor: "#22485E"}}>About</Button>
        <Button variant="contained" size='large' style={{color: '#b4dac3', backgroundColor: "#22485E"}}>Contact Me</Button>
      </Stack>
      // </div>
    )
}


// old text color was #ddbea8