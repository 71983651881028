import "./Footer.css"
import { Box, Divider, Link, Stack, Typography } from "@mui/material";

function Footer() {
    return (
      <Box 
        className='Footer' 
        style={{ backgroundColor: '#1b3a4b', color: "#ffffff" }}
        display={"flex"}
        justifyContent={"center"}
        textAlign={"center"}        
        
        // height={"10rem"}
        >
          <Stack width={"50rem"} spacing={"0.8rem"}>
            <Typography color="#b4dac3">
              Copyright © 2025 Trenton Peters
            </Typography>
            
            <Divider variant="middle" sx={{ backgroundColor: "#ffffff", opacity: 0.4}} />

            <Typography color="#b4dac3">
              Sites:
            </Typography>
            <Link color="#b4dac3">
              About This Website
            </Link>
            <Link color="#b4dac3">
              Contact Me
            </Link>
            <Link color="#b4dac3">
              My Profile
            </Link>
            <Link color="#b4dac3">
              Privacy Policy
            </Link>
          </Stack>
      </Box>
    )
}
export default Footer;